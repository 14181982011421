import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "@src/components/core-button"
import { useViewport } from "@src/layouts/viewport"

const DIRECTIONS = {
  prev: "prev",
  next: "next",
}

const ContentContainer = styled.div`
  position: relative;
  & > .carousel-content {
    scroll-behavior: smooth;
    scroll-snap-type: ${props =>
      props.variant === "routes" ? "none" : "x mandatory"};
    position: relative;
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    & > * {
      margin: 0 4px;
    }
    & > a,
    & > div {
      scroll-snap-align: start;
    }
  }

  & > button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: ${props => props.theme.layers.floatingUI};
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    &:not(.show) {
      opacity: ${props => (props.$alwaysShowButtons ? "1" : "0")};
      cursor: ${props => (props.$alwaysShowButtons ? "disabled" : "default")};
    }
    &.prev {
      left: 8px;
    }
    &.next {
      right: 8px;
    }
    @media only screen and (${props => props.theme.screen.small.min}) {
      &.prev {
        left: 16px;
      }
      &.next {
        right: 16px;
      }
    }
  }
`

const ContentCarousel = ({
  children,
  className,
  active,
  buttonType,
  buttonColor,
  mobileSizeOnly,
  icons,
  alwaysShowButtons,
  variant,
}) => {
  const [showButtons, setShowButtons] = useState(true)
  const ref = useRef(null)
  const { width } = useViewport()

  const refreshButtons = () => {
    const shouldShowButtons =
      ref.current.clientWidth !== ref.current.scrollWidth
    // need to account for margins between items since scroll-snap snaps to edge of the element
    const marginWidth = 4
    setShowButtons({
      left: shouldShowButtons && ref.current.scrollLeft > marginWidth,
      right:
        shouldShowButtons &&
        ref.current.scrollLeft + ref.current.clientWidth + marginWidth <
          ref.current.scrollWidth,
    })
  }

  useEffect(() => {
    const carouselRef = ref.current
    if (carouselRef) {
      carouselRef.addEventListener("scroll", refreshButtons, false)
      return () => {
        carouselRef.removeEventListener("scroll", refreshButtons, false)
      }
    }
  }, [])

  useEffect(() => {
    refreshButtons()
  }, [width])
  useEffect(() => {
    if (active) {
      refreshButtons()
    }
  }, [active])

  const scrollCarousel = (direction = DIRECTIONS.next) => {
    if (ref.current) {
      const newScrollLeft =
        ref.current.scrollLeft +
        (direction === DIRECTIONS.next
          ? ref.current.offsetWidth
          : -ref.current.offsetWidth)

      if (newScrollLeft > ref.current.scrollWidth) {
        ref.current.scrollLeft = ref.current.scrollWidth
      } else {
        ref.current.scrollLeft = newScrollLeft
      }
    }
  }

  return (
    <>
      <ContentContainer
        className={`carousel-container ${className ? className : ""}`}
        $alwaysShowButtons={alwaysShowButtons}
        variant={variant}
      >
        <div ref={ref} className="carousel-content">
          {children}
        </div>
        <Button
          className={`prev${showButtons.left ? " show" : ""}`}
          disabled={!showButtons.left}
          variant={buttonType}
          color={buttonColor}
          mobileSizeOnly={mobileSizeOnly}
          icon={icons.left}
          onClick={() => scrollCarousel(DIRECTIONS.prev)}
          aria-label="previous"
        />
        <Button
          className={`next${showButtons.right ? " show" : ""}`}
          disabled={!showButtons.right}
          variant={buttonType}
          color={buttonColor}
          mobileSizeOnly={mobileSizeOnly}
          icon={icons.right}
          onClick={() => scrollCarousel(DIRECTIONS.next)}
          aria-label="next"
        />
      </ContentContainer>
    </>
  )
}

ContentCarousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  active: PropTypes.bool,
  buttonType: PropTypes.oneOf(["circle", "icon", "none"]),
  buttonColor: PropTypes.oneOf([
    "yellow",
    "blue",
    "yellow-black",
    "outline",
    "outline-black",
    "gray",
    "purple",
    "red",
    "white-gray",
  ]),
  icons: PropTypes.shape({ left: PropTypes.string, right: PropTypes.string }),
  mobileSizeOnly: PropTypes.bool,
  alwaysShowButtons: PropTypes.bool,
  variant: PropTypes.string,
}
ContentCarousel.defaultProps = {
  buttonType: "circle",
  icons: {
    left: "chevron-left",
    right: "chevron-right",
  },
  mobileSizeOnly: false,
  alwaysShowButtons: false,
}
export default ContentCarousel
