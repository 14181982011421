import { useStaticQuery, graphql } from "gatsby"

export const useTourContinents = () => {
  const {
    tenTours: {
      tourRegions: { nodes: tourContinents },
    },
  } = useStaticQuery(graphql`
    query TourContinentsQuery {
      tenTours {
        tourRegions(where: { parent: null }) {
          nodes {
            id: databaseId
            name
            slug
            uri
          }
        }
      }
    }
  `)
  return tourContinents
}
