import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { generateMeta } from "@src/utils/page-data"
import HeaderHome from "@src/components/page-header-home"
import ResponsiveSection from "@src/components/container-responsive-section"
import IconContentBar from "@src/components/core-icon-bar"
import RegionTiles from "@src/components/content-region-tiles"
import TourTypeTiles from "@src/components/content-tour-type-tiles"
import ActivityTypeTiles from "@src/components/content-activity-type-tiles"
import PostTiles from "@src/components/content-post-tiles"
import { PostTileQueryPropTypes } from "@src/components/content-post-tile"
import SocialProofReasons from "@src/components/content-social-proof-reasons"
import SocialProofMediaPartners from "@src/components/content-social-proof-media-partners"
import Button from "@src/components/core-button"
import { ImagePropTypes } from "@src/components/core-image"
import { ICON_BAR_HOME_1 } from "@src/utils/constants/icon-bar-home"
import {
  BASE_PATH_BLOG,
  BASE_PATH_TOURS,
  BASE_PATH_MAP,
} from "@src/utils/constants"
import { ucwords } from "@src/utils/strings"
import { orderByField } from "@src/utils/sorting"
import HtmlContent from "@src/components/core-value-html"
import generateOrganization from "@src/utils/structured-data/generateOrganization"
import VideoTestimonials from "@src/components/content-social-proof-video-testimonials"

const H1Container = styled(ResponsiveSection)`
  background: #fbf8f5;
  padding: 170px 0 40px;
  padding-bottom: 60px;
  margin-bottom: 20px;
  & h1 {
    margin-right: 40px;
    font-family: system-ui;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #315254;
    & .underline {
      border-bottom: 3px solid #629d94;
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 200px 0 80px;
    & h1 {
      margin-right: 0;
      text-align: center;
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    padding-top: 100px;
    & h1 {
      margin-right: 0;
      text-align: center;
    }
  }
`
const StyledHtmlContent = styled(HtmlContent)`
  & h2 {
    text-align: center;
  }
`

const HomePage = ({ data, pageContext }) => {
  const {
    tenAdventures: {
      page: {
        uri: pageUri,
        content: pageContent,
        images: pageImages,
        seo: pageSeo,
      },
      posts: { nodes: posts },
      routeActivities: { nodes: routeActivities },
      routeRegions: { nodes: routeRegions },
    },
    tenTours: {
      tourRegions: { nodes: tourRegions },
      tourCategories: { nodes: tourCategories },
    },
  } = data
  const {
    routeActivities: routeActivitiesOrder,
    routeRegions: routeRegionsOrder,
    tourCategories: tourCategoriesOrder,
    tourRegions: tourRegionsOrder,
  } = pageContext
  GTM.dataLayerPushPageInfo({
    template: "static-home",
  })

  return (
    <PrimaryLayout pagePath={pageUri}>
      <PageHelmet
        title="10Adventures: Find Your Next Adventure"
        meta={generateMeta(pageUri, { ...pageSeo, metaRobotsNoindex: "index" })}
        structuredData={generateOrganization({
          image: pageImages.header.src,
        })}
      />
      <HeaderHome image={pageImages.header} />
      <H1Container className="homepage-h1-container">
        <h1>
          Book <span className="underline">personalized,</span> active vacations
          with 10Adventures
        </h1>
      </H1Container>
      <ResponsiveSection>
        <VideoTestimonials />
      </ResponsiveSection>
      <SocialProofReasons />
      <ResponsiveSection variant="tiles">
        <h2>Experience Tours in These Destinations</h2>
        <RegionTiles
          content={tourRegions
            ?.sort(orderByField("slug", tourRegionsOrder))
            .map(tourRegion => ({
              title: tourRegion.title,
              link: tourRegion.uri,
              image: tourRegion.images?.featureTile,
            }))}
          actions={[
            <Button to={BASE_PATH_TOURS} key={`view-tours`}>
              View Tours
            </Button>,
          ]}
        />
        <TourTypeTiles
          content={tourCategories
            ?.sort(orderByField("slug", tourCategoriesOrder))
            .map(tourCategory => ({
              title: `${tourCategory.title} Tours`,
              link: tourCategory.uri.replace("/activity/", "/tours/"),
              image: tourCategory.images?.featureTile,
            }))}
        />
      </ResponsiveSection>
      <ResponsiveSection variant="blue" fullWidth>
        <h3>
          There’s a reason over 1 million people used 10Adventures last year...
        </h3>
      </ResponsiveSection>
      <ResponsiveSection variant="gray">
        <h3>Use 10Adventures to plan your own trip</h3>
        <IconContentBar icons={ICON_BAR_HOME_1} />
      </ResponsiveSection>
      <ResponsiveSection variant="tiles">
        <h2>Explore Route Guides</h2>
        <RegionTiles
          content={routeRegions
            ?.sort(orderByField("slug", routeRegionsOrder))
            .map(routeRegion => ({
              title: routeRegion.title,
              link: routeRegion.uri,
              image: routeRegion.images?.featureTile,
            }))}
          actions={[
            <Button to={BASE_PATH_MAP} key={`view-route-guides`}>
              Free Trail Guides
            </Button>,
          ]}
        />
        <ActivityTypeTiles
          content={routeActivities
            ?.sort(orderByField("slug", routeActivitiesOrder))
            .map(routeActivity => ({
              title: `${ucwords(routeActivity.title)} Route Guides`,
              link: routeActivity.uri,
              image: routeActivity.images?.featureTile,
            }))}
        />
      </ResponsiveSection>
      <SocialProofMediaPartners />
      <ResponsiveSection variant="tiles">
        <h2>Recent Stories</h2>
        <PostTiles content={posts} viewMore={BASE_PATH_BLOG} />
      </ResponsiveSection>
      <ResponsiveSection>
        <StyledHtmlContent html={pageContent} />
      </ResponsiveSection>
    </PrimaryLayout>
  )
}
export default HomePage
HomePage.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        uri: PropTypes.string,
        content: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
      posts: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({ ...PostTileQueryPropTypes })
        ),
      }),
      routeActivities: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            images: PropTypes.shape({
              featureTile: PropTypes.shape({ ...ImagePropTypes }),
            }),
          })
        ),
      }),
      routeRegions: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            images: PropTypes.shape({
              featureTile: PropTypes.shape({ ...ImagePropTypes }),
            }),
          })
        ),
      }),
    }),
    tenTours: PropTypes.shape({
      tourRegions: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            images: PropTypes.shape({
              featureTile: PropTypes.shape({ ...ImagePropTypes }),
            }),
          })
        ),
      }),
      tourCategories: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            images: PropTypes.shape({
              featureTile: PropTypes.shape({ ...ImagePropTypes }),
            }),
          })
        ),
      }),
    }),
  }),
  pageContext: PropTypes.shape({
    routeActivities: PropTypes.arrayOf(PropTypes.string),
    routeRegions: PropTypes.arrayOf(PropTypes.string),
    tourCategories: PropTypes.arrayOf(PropTypes.string),
    tourRegions: PropTypes.arrayOf(PropTypes.string),
  }),
}
export const homePageQuery = graphql`
  query (
    $id: ID!
    $routeActivities: [String]
    $routeRegions: [String]
    $tourCategories: [String]
    $tourRegions: [String]
  ) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        uri
        content
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
      posts(first: 4) {
        nodes {
          ...TenAdventuresGQL_PostDetailsQuery
        }
      }
      routeActivities: activityTypes(where: { slug: $routeActivities }) {
        nodes {
          uri
          slug
          title: nameSport
          images {
            featureTile {
              ...TenAdventuresGQL_ImageSizesQuery
            }
          }
        }
      }
      routeRegions: regions(where: { slug: $routeRegions }) {
        nodes {
          uri
          slug
          title: name
          images {
            featureTile {
              ...TenAdventuresGQL_ImageSizesQuery
            }
          }
        }
      }
    }
    tenTours {
      tourRegions(where: { slug: $tourRegions }) {
        nodes {
          uri
          slug
          title: name
          images {
            featureTile {
              ...TenToursGQL_ImageSizesQuery
            }
          }
        }
      }
      tourCategories(where: { slug: $tourCategories }) {
        nodes {
          uri
          slug
          title: name
          images {
            featureTile {
              ...TenToursGQL_ImageSizesQuery
            }
          }
        }
      }
    }
  }
`
