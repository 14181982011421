export const ICON_BAR_HOME_1 = [
  {
    icon: "route",
    text: "Quality Route Guides",
  },
  {
    icon: "thumbs-up",
    text: "Free Route Guides",
  },
  {
    icon: "file-download",
    text: "Offline Access",
  },
  {
    icon: "pin",
    text: "GPS App",
  },
]
