import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import EntrySearch from "@src/components/cta-entry-search"
import ResponsiveSection from "./container-responsive-section"
const HeaderHomeStyled = styled.section`
  position: relative;
  min-height: 320px;
  width: 100%;

  background-color: ${props => props.fallBackBg && props.theme.placeholder};

  & section {
    position: absolute;
    bottom: -140px;
  }
  & .header-text {
    color: ${props => props.theme.white};
    text-align: left;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    & p.h2 {
      font-family: system-ui;
      font-size: 32px;
      font-weight: 700;
      line-height: 44px;
      display: block;
    }
    & p {
      font-family: system-ui;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      display: none;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    min-height: 480px;
    & section {
      bottom: -160px;
      justify-items: center;
    }
    & .header-text {
      width: 100%;
      max-width: 755px;
      margin-bottom: 50px;
      .h2,
      p {
        max-width: 468px;
      }
      & p.h2 {
        font-size: 46px;
        font-weight: 900;
        line-height: 64px;
      }
      & p {
        display: block;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & section {
      width: 100%;
      bottom: -60px;
      justify-items: baseline;
    }
    & .header-text {
      width: 755px;
      justify-self: baseline;
      margin-bottom: 80px;
    }
    & .entry-search {
      margin-left: 36px;
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    & .entry-search {
      margin-left: 12px;
    }
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    & .entry-search {
      margin-left: 80px;
    }
  }
`
const HeaderImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(50%);
`

const HeaderHome = ({ image }) => {
  return (
    <HeaderHomeStyled>
      <HeaderImage
        artDirection={{ mobile: "100vw", small: "100vw" }}
        lazyload={false}
        {...image}
      />
      <ResponsiveSection>
        <div className="header-text">
          <p className="h2">
            Make Memories.
            <br />
            Explore the World.
          </p>
          <p>
            Spend your time making memories, not planning them. Use 10Adventures
            to book a custom, private, or personalized active or adventure
            holiday in over 85 countries.
          </p>
        </div>
        <EntrySearch />
      </ResponsiveSection>
    </HeaderHomeStyled>
  )
}
HeaderHome.propTypes = {
  image: PropTypes.shape({ ...ImagePropTypes }),
}
export default HeaderHome
