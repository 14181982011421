import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTourCategories } from "@src/hooks/useTourCategories"
import { useTourRegionsWithCategories } from "@src/hooks/useTourRegionsWithCategories"
import InputWrapper from "@src/components/input-wrapper"
import InputDropdown, {
  filterDropdownText,
} from "@src/components/input-dropdown"
import Button from "@src/components/core-button"
import { BASE_PATH_TOURS } from "@src/utils/constants"
import { TOUR_REGION_CATEGORY_INCLUSION_IDS } from "@src/utils/constants/region-activity-inclusions"

const EntrySearch = styled.div`
  font-family: "system-ui";
  width: 100%;

  & .search,
  & .search-header {
    border: 1px solid ${props => props.theme.borderGray};
    background-color: ${props => props.theme.white};
    padding: 14px 20px;
  }
  & .search-header {
    width: fit-content;
    margin: 0;
    border-radius: 8px 8px 0 0;
  }
  & .search {
    border-radius: 0 8px 8px;
    & input {
      border-radius: 8px;
      border-color: ${props => props.theme.borderGray};
    }
    & a {
      font-weight: 700;
      background: #41c689;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    max-width: 755px;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 755px;
    & .search {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 20px 30px;

      & .field-wrapper {
        margin-bottom: 0;
        width: 237px;
        & input {
          height: 37px;
        }
      }

      & a {
        width: unset;
        padding: 16px 24px;
      }
    }
  }
`

const filterTopLevel = item => !item.parentId
const filterOptionsByName = (filterValue, optionList, initOptions = []) =>
  !filterValue || !optionList || !optionList.length
    ? []
    : optionList.reduce((result, option) => {
        if (
          (filterValue.length < 3 &&
            filterDropdownText(option.name, filterValue)) ||
          (filterValue.length >= 3 &&
            option.name.toLowerCase().includes(filterValue.toLowerCase()))
        ) {
          result.push({
            text: option.name,
            value: option.slug,
            uri: option.uri,
            data: option,
          })
        }
        return result
      }, initOptions || [])

const tourRegionHasCategory = (tourRegion, tourCategory) => {
  if (
    tourCategory &&
    tourCategory.id &&
    tourRegion &&
    tourRegion.tourCategories?.nodes?.length
  ) {
    return tourRegion.tourCategories.nodes.some(
      check =>
        check.id === tourCategory.id &&
        TOUR_REGION_CATEGORY_INCLUSION_IDS.includes(check.id)
    )
  }
  return false
}

const CTAEntrySearch = () => {
  const [targetLink, setTargetLink] = useState(BASE_PATH_TOURS)
  const [tourRegion, setTourRegion] = useState(null)
  const [tourCategory, setTourCategory] = useState(null)

  // tourCategories
  const tourCategories = useTourCategories()
  const emptyTourCategory = "Ex: Climbing"
  const emptyTourCategoryOption = { text: "Explore All Tours", value: null }

  // tourRegions
  const { tourRegionsWithCategories: tourRegions } =
    useTourRegionsWithCategories()

  const emptyTourRegion = "Ex: Canada"
  const emptyTourRegionOption = {
    text: "Explore Adventure Tours Worldwide",
    value: null,
  }

  const filterSelection = selection => {
    if (selection && selection.value === null) {
      return null
    }
    return selection
  }
  const filterCategoryAllowed = tourCategory => {
    if (tourRegion && tourCategory) {
      return tourRegionHasCategory(tourRegion.data, tourCategory.data)
    }
    return true
  }
  const filterRegionAllowed = tourRegion => {
    if (tourRegion && tourCategory) {
      return tourRegionHasCategory(tourRegion.data, tourCategory.data)
    }
    return true
  }

  useEffect(() => {
    let targetLink = BASE_PATH_TOURS
    const tourRegionSlug = (tourRegion && tourRegion.value) || ""
    const tourCategorySlug = (tourCategory && tourCategory.value) || ""
    if (tourRegionSlug && tourCategorySlug) {
      targetLink = `${BASE_PATH_TOURS}${tourRegionSlug}-${tourCategorySlug}/`
    } else if (tourRegionSlug) {
      targetLink = `${BASE_PATH_TOURS}${tourRegionSlug}/`
    } else if (tourCategorySlug) {
      targetLink = `${BASE_PATH_TOURS}${tourCategorySlug}/`
    }
    setTargetLink(targetLink)
  }, [tourRegion, tourCategory])

  const handleTourRegionChange = value => {
    setTourRegion(value || null)

    // avoid impossible combination
    if (
      value &&
      tourCategory &&
      !tourRegionHasCategory(value.data, tourCategory.data)
    ) {
      setTourCategory(null)
    }
  }
  const handleTourCategoryChange = value => {
    setTourCategory(value || null)

    // avoid impossible combination
    if (
      value &&
      tourRegion &&
      !tourRegionHasCategory(tourRegion.data, value.data)
    ) {
      setTourRegion(null)
    }
  }

  return (
    <EntrySearch className="entry-search">
      <p className="search-header">🛫 Tours</p>
      <div className="search">
        <InputWrapper>
          <p className="input-header">🌎 Destination</p>
          <InputDropdown
            combobox
            clearButton
            placeholder={emptyTourRegion}
            filterSelection={filterSelection}
            onChange={handleTourRegionChange}
            options={[]}
            getFilteredOptions={filterValue =>
              filterOptionsByName(
                filterValue,
                tourRegions.filter(data => filterRegionAllowed({ data })),
                [emptyTourRegionOption]
              )
            }
          />
        </InputWrapper>
        <InputWrapper>
          <p className="input-header">🛶 Activity</p>
          <InputDropdown
            combobox
            clearButton
            placeholder={emptyTourCategory}
            filterSelection={filterSelection}
            onChange={handleTourCategoryChange}
            options={[emptyTourCategoryOption].concat(
              tourCategories.reduce((result, tourCategory) => {
                if (
                  filterTopLevel(tourCategory) &&
                  filterCategoryAllowed({ data: tourCategory })
                ) {
                  result.push({
                    text: `${tourCategory.name} Tours`,
                    value: tourCategory.slug,
                    uri: tourCategory.uri,
                    data: tourCategory,
                  })
                }
                return result
              }, [])
            )}
            getFilteredOptions={filterValue =>
              filterOptionsByName(
                filterValue,
                tourCategories.filter(data => filterCategoryAllowed({ data })),
                [emptyTourCategoryOption]
              )
            }
          />
        </InputWrapper>
        <Button
          id="cta-entry-search-button"
          size="full-width"
          to={targetLink || "/"}
          disabled={!targetLink}
        >
          Explore Now
        </Button>
      </div>
    </EntrySearch>
  )
}

export default CTAEntrySearch
